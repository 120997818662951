/* eslint-disable prettier/prettier */
/* based on https://github.com/joaoeudes7/V-Emoji-Picker */
class Emoji {
  constructor(emoji, description, category, aliases, tags) {
    this.emoji = emoji;
    this.description = description;
    this.category = category;
    this.aliases = aliases;
    this.tags = tags;
  }
}

export default [
  new Emoji("😀", "grinning face", "Peoples", ["grinning"], ["smile","happy"]),
  new Emoji("😃", "smiling face with open mouth", "Peoples", ["smiley"], ["happy","joy","haha"]),
  new Emoji("😄", "smiling face with open mouth & smiling eyes", "Peoples", ["smile"], ["happy","joy","laugh","pleased"]),
  new Emoji("😁", "grinning face with smiling eyes", "Peoples", ["grin"], []),
  new Emoji("😆", "smiling face with open mouth & closed eyes", "Peoples", ["laughing","satisfied"], ["happy","haha"]),
  new Emoji("😅", "smiling face with open mouth & cold sweat", "Peoples", ["sweat_smile"], ["hot"]),
  new Emoji("😂", "face with tears of joy", "Peoples", ["joy"], ["tears"]),
  new Emoji("🤣", "rolling on the floor laughing", "Peoples", ["rofl"], ["lol","laughing"]),
  new Emoji("😌", "smiling face", "Peoples", ["relaxed"], ["blush","pleased"]),
  new Emoji("😊", "smiling face with smiling eyes", "Peoples", ["blush"], ["proud"]),
  new Emoji("😇", "smiling face with halo", "Peoples", ["innocent"], ["angel"]),
  new Emoji("🙂", "slightly smiling face", "Peoples", ["slightly_smiling_face"], []),
  new Emoji("🙃", "upside-down face", "Peoples", ["upside_down_face"], []),
  new Emoji("😉", "winking face", "Peoples", ["wink"], ["flirt"]),
  new Emoji("😌", "relieved face", "Peoples", ["relieved"], ["whew"]),
  new Emoji("😍", "smiling face with heart-eyes", "Peoples", ["heart_eyes"], ["love","crush"]),
  new Emoji("😘", "face blowing a kiss", "Peoples", ["kissing_heart"], ["flirt"]),
  new Emoji("😗", "kissing face", "Peoples", ["kissing"], []),
  new Emoji("😙", "kissing face with smiling eyes", "Peoples", ["kissing_smiling_eyes"], []),
  new Emoji("😚", "kissing face with closed eyes", "Peoples", ["kissing_closed_eyes"], []),
  new Emoji("😋", "face savouring delicious food", "Peoples", ["yum"], ["tongue","lick"]),
  new Emoji("😜", "face with stuck-out tongue & winking eye", "Peoples", ["stuck_out_tongue_winking_eye"], ["prank","silly"]),
  new Emoji("😝", "face with stuck-out tongue & closed eyes", "Peoples", ["stuck_out_tongue_closed_eyes"], ["prank"]),
  new Emoji("😛", "face with stuck-out tongue", "Peoples", ["stuck_out_tongue"], []),
  new Emoji("🤑", "money-mouth face", "Peoples", ["money_mouth_face"], ["rich"]),
  new Emoji("🤗", "hugging face", "Peoples", ["hugs"], []),
  new Emoji("🤓", "nerd face", "Peoples", ["nerd_face"], ["geek","glasses"]),
  new Emoji("😎", "smiling face with sunglasses", "Peoples", ["sunglasses"], ["cool"]),
  new Emoji("🤠", "cowboy hat face", "Peoples", ["cowboy_hat_face"], []),
  new Emoji("😏", "smirking face", "Peoples", ["smirk"], ["smug"]),
  new Emoji("😒", "unamused face", "Peoples", ["unamused"], ["meh"]),
  new Emoji("😞", "disappointed face", "Peoples", ["disappointed"], ["sad"]),
  new Emoji("😔", "pensive face", "Peoples", ["pensive"], []),
  new Emoji("😟", "worried face", "Peoples", ["worried"], ["nervous"]),
  new Emoji("😕", "confused face", "Peoples", ["confused"], []),
  new Emoji("🙁", "slightly frowning face", "Peoples", ["slightly_frowning_face"], []),
  new Emoji("☹️", "frowning face", "Peoples", ["frowning_face"], []),
  new Emoji("😣", "persevering face", "Peoples", ["persevere"], ["struggling"]),
  new Emoji("😖", "confounded face", "Peoples", ["confounded"], []),
  new Emoji("😫", "tired face", "Peoples", ["tired_face"], ["upset","whine"]),
  new Emoji("😩", "weary face", "Peoples", ["weary"], ["tired"]),
  new Emoji("😤", "face with steam from nose", "Peoples", ["triumph"], ["smug"]),
  new Emoji("😠", "angry face", "Peoples", ["angry"], ["mad","annoyed"]),
  new Emoji("😡", "pouting face", "Peoples", ["rage","pout"], ["angry"]),
  new Emoji("😶", "face without mouth", "Peoples", ["no_mouth"], ["mute","silence"]),
  new Emoji("😐", "neutral face", "Peoples", ["neutral_face"], ["meh"]),
  new Emoji("😑", "expressionless face", "Peoples", ["expressionless"], []),
  new Emoji("😯", "hushed face", "Peoples", ["hushed"], ["silence","speechless"]),
  new Emoji("😦", "frowning face with open mouth", "Peoples", ["frowning"], []),
  new Emoji("😧", "anguished face", "Peoples", ["anguished"], ["stunned"]),
  new Emoji("😮", "face with open mouth", "Peoples", ["open_mouth"], ["surprise","impressed","wow"]),
  new Emoji("😲", "astonished face", "Peoples", ["astonished"], ["amazed","gasp"]),
  new Emoji("😵", "dizzy face", "Peoples", ["dizzy_face"], []),
  new Emoji("😳", "flushed face", "Peoples", ["flushed"], []),
  new Emoji("😱", "face screaming in fear", "Peoples", ["scream"], ["horror","shocked"]),
  new Emoji("😨", "fearful face", "Peoples", ["fearful"], ["scared","shocked","oops"]),
  new Emoji("😰", "face with open mouth & cold sweat", "Peoples", ["cold_sweat"], ["nervous"]),
  new Emoji("😢", "crying face", "Peoples", ["cry"], ["sad","tear"]),
  new Emoji("😥", "disappointed but relieved face", "Peoples", ["disappointed_relieved"], ["phew","sweat","nervous"]),
  new Emoji("🤤", "drooling face", "Peoples", ["drooling_face"], []),
  new Emoji("😭", "loudly crying face", "Peoples", ["sob"], ["sad","cry","bawling"]),
  new Emoji("😓", "face with cold sweat", "Peoples", ["sweat"], []),
  new Emoji("😪", "sleepy face", "Peoples", ["sleepy"], ["tired"]),
  new Emoji("😴", "sleeping face", "Peoples", ["sleeping"], ["zzz"]),
  new Emoji("🙄", "face with rolling eyes", "Peoples", ["roll_eyes"], []),
  new Emoji("🤔", "thinking face", "Peoples", ["thinking"], []),
  new Emoji("🤥", "lying face", "Peoples", ["lying_face"], ["liar"]),
  new Emoji("😬", "grimacing face", "Peoples", ["grimacing"], []),
  new Emoji("🤐", "zipper-mouth face", "Peoples", ["zipper_mouth_face"], ["silence","hush"]),
  new Emoji("🤢", "nauseated face", "Peoples", ["nauseated_face"], ["sick","barf","disgusted"]),
  new Emoji("🤧", "sneezing face", "Peoples", ["sneezing_face"], ["achoo","sick"]),
  new Emoji("😷", "face with medical mask", "Peoples", ["mask"], ["sick","ill"]),
  new Emoji("🤒", "face with thermometer", "Peoples", ["face_with_thermometer"], ["sick"]),
  new Emoji("🤕", "face with head-bandage", "Peoples", ["face_with_head_bandage"], ["hurt"]),
  new Emoji("👐", "open hands", "Peoples", ["open_hands"], []),
  new Emoji("🙌", "raising hands", "Peoples", ["raised_hands"], ["hooray"]),
  new Emoji("👏", "clapping hands", "Peoples", ["clap"], ["praise","applause"]),
  new Emoji("🙏", "folded hands", "Peoples", ["pray"], ["please","hope","wish"]),
  new Emoji("🤝", "handshake", "Peoples", ["handshake"], ["deal"]),
  new Emoji("👍", "thumbs up", "Peoples", ["+1","thumbsup"], ["approve","ok"]),
  new Emoji("👎", "thumbs down", "Peoples", ["-1","thumbsdown"], ["disapprove","bury"]),
  new Emoji("👊", "oncoming fist", "Peoples", ["fist_oncoming","facepunch","punch"], ["attack"]),
  new Emoji("✊", "raised fist", "Peoples", ["fist_raised","fist"], ["power"]),
  new Emoji("🤛", "left-facing fist", "Peoples", ["fist_left"], []),
  new Emoji("🤜", "right-facing fist", "Peoples", ["fist_right"], []),
  new Emoji("🤞", "crossed fingers", "Peoples", ["crossed_fingers"], ["luck","hopeful"]),
  new Emoji("✌️", "victory hand", "Peoples", ["v"], ["victory","peace"]),
  new Emoji("🤘", "sign of the horns", "Peoples", ["metal"], []),
  new Emoji("👌", "OK hand", "Peoples", ["ok_hand"], []),
  new Emoji("👈", "backhand index pointing left", "Peoples", ["point_left"], []),
  new Emoji("👉", "backhand index pointing right", "Peoples", ["point_right"], []),
  new Emoji("👆", "backhand index pointing up", "Peoples", ["point_up_2"], []),
  new Emoji("👇", "backhand index pointing down", "Peoples", ["point_down"], []),
  new Emoji("☝️", "index pointing up", "Peoples", ["point_up"], []),
  new Emoji("✋", "raised hand", "Peoples", ["hand","raised_hand"], ["highfive","stop"]),
  new Emoji("🤚", "raised back of hand", "Peoples", ["raised_back_of_hand"], []),
  new Emoji("🖐", "raised hand with fingers splayed", "Peoples", ["raised_hand_with_fingers_splayed"], []),
  new Emoji("🖖", "vulcan salute", "Peoples", ["vulcan_salute"], ["prosper","spock"]),
];
